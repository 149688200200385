import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react'
import jwtDecode from 'jwt-decode'
import { navigate } from 'gatsby'

const TokenContext = createContext({
  isAuthVerified: false,
  authToken: null,
  csrfToken: null,
  verifyMagicLinkToken: async () => false,
  verifyAuthToken: async () => false,
  logout: async () => {},
  refreshAuthTokens: async () => false,
  getAuthToken: async () => null,
  fetchCsrfToken: async () => null,
  loading: false,
  error: null,
})

export const useToken = () => useContext(TokenContext)

const getCookie = (name) => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';').map((c) => c.trim())
  for (let i = 0; i < ca.length; i++) {
    if (ca[i].indexOf(nameEQ) === 0)
      return decodeURIComponent(ca[i].substring(nameEQ.length))
  }
  return null
}

export const TokenProvider = ({ children }) => {
  const [isAuthVerified, setIsAuthVerified] = useState(false)
  const [authToken, setAuthToken] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [csrfToken, setCsrfToken] = useState('')
  const refreshAuthPromiseRef = useRef(null)
  const csrfFetchPromiseRef = useRef(null)

  const fetchCsrfToken = useCallback(async () => {
    if (csrfToken || getCookie('x-csrf-token')) {
      return csrfToken || getCookie('x-csrf-token')
    }

    if (csrfFetchPromiseRef.current) {
      return csrfFetchPromiseRef.current
    }

    csrfFetchPromiseRef.current = (async () => {
      try {
        const response = await fetch('/api/csrf-token', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.json()
        setCsrfToken(data.token)
        return data.token
      } catch (error) {
        setError('Failed to fetch CSRF token.')
        return null
      } finally {
        csrfFetchPromiseRef.current = null
      }
    })()

    return csrfFetchPromiseRef.current
  }, [csrfToken])

  const isTokenExpired = useCallback((token, threshold = 60) => {
    if (!token) return true
    try {
      const decoded = jwtDecode(token)
      const currentTime = Date.now() / 1000
      return decoded.exp < currentTime + threshold
    } catch (e) {
      return true
    }
  }, [])

  const makePostRequest = useCallback(
    async (url, body) => {
      setLoading(true)
      try {
        let tokenToUse = csrfToken

        if (!tokenToUse) {
          const cookieCsrfToken = getCookie('x-csrf-token')
          tokenToUse = cookieCsrfToken || (await fetchCsrfToken())
          if (!tokenToUse) {
            throw new Error('Failed to obtain CSRF token.')
          }
          setCsrfToken(tokenToUse)
        }

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': tokenToUse,
          },
          body: JSON.stringify(body),
          credentials: 'include',
        })

        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.error || 'Request failed.')
        }

        const data = await response.json()
        return data
      } catch (err) {
        setError(err.message || 'An error occurred during the request.')
        return null
      } finally {
        setLoading(false)
      }
    },
    [csrfToken, fetchCsrfToken]
  )

  const verifyMagicLinkToken = useCallback(
    async (token) => {
      try {
        const data = await makePostRequest('/api/auth-verify-token', {
          token,
          tokenType: 'magic-link',
        })
        if (data.token && data.firstUse) {
          setAuthToken(data.token)
          setIsAuthVerified(true)
          return { token: data.token, firstUse: true }
        }
        return { token: data.token }
      } catch (err) {
        setError(
          err.message || 'An error occurred during magic link verification.'
        )
        return false
      }
    },
    [makePostRequest, authToken]
  )

  const verifyAuthToken = useCallback(
    async (token) => {
      try {
        const data = await makePostRequest('/api/auth-verify-token', {
          token,
          tokenType: 'auth',
        })
        if (data && data.token) {
          setAuthToken(data.token)
          setIsAuthVerified(true)
          return data.token
        }
        setIsAuthVerified(false)
        setAuthToken(null)
        return false
      } catch (err) {
        setError(
          err.message || 'An error occurred during auth token verification.'
        )
        setIsAuthVerified(false)
        setAuthToken(null)
        return false
      }
    },
    [makePostRequest]
  )

  const refreshAuthTokens = useCallback(async () => {
    if (refreshAuthPromiseRef.current) {
      return refreshAuthPromiseRef.current
    }

    refreshAuthPromiseRef.current = (async () => {
      try {
        const data = await makePostRequest('/api/auth-refresh-token', {
          token: authToken,
          tokenType: 'auth',
        })

        if (data && data.authToken) {
          setAuthToken(data.authToken)
          setIsAuthVerified(true)
          return true
        }

        setIsAuthVerified(false)
        setAuthToken(null)
        return false
      } catch (err) {
        setError(err.message || 'An error occurred during auth token refresh.')
        setIsAuthVerified(false)
        setAuthToken(null)
        return false
      } finally {
        refreshAuthPromiseRef.current = null
      }
    })()

    return refreshAuthPromiseRef.current
  }, [makePostRequest, authToken])

  const getAuthToken = useCallback(async () => {
    setError(null)
    try {
      if (authToken && isAuthVerified && !isTokenExpired(authToken)) {
        return authToken
      }

      if (authToken && isAuthVerified && isTokenExpired(authToken)) {
        const refreshed = await refreshAuthTokens()
        if (refreshed && authToken && !isTokenExpired(authToken)) {
          return authToken
        }
      }

      if (authToken && !isAuthVerified) {
        const verified = await verifyAuthToken(authToken)
        if (verified) {
          return authToken
        }
      }

      const refreshed = await refreshAuthTokens()
      if (refreshed) {
        return authToken
      }
      return null
    } finally {
    }
  }, [
    authToken,
    isAuthVerified,
    verifyAuthToken,
    refreshAuthTokens,
    isTokenExpired,
  ])

  const logout = useCallback(async () => {
    try {
      const data = await makePostRequest('/api/logout', {})
      if (data) {
        setIsAuthVerified(false)
        setAuthToken(null)
        navigate('/login-form', { replace: true })
      }
    } catch (error) {
      setError('Logout failed.')
    }
  }, [makePostRequest])

  const contextValue = {
    isAuthVerified,
    authToken,
    csrfToken,
    verifyAuthToken,
    verifyMagicLinkToken,
    logout,
    refreshAuthTokens,
    getAuthToken,
    fetchCsrfToken,
    loading,
    error,
  }

  return (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  )
}
