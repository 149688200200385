import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  startTransition,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { sanitizeConfig } from '../../utils/sanitization'
import FormRenderer from './FormRenderer'
import RecaptchaWrapper from './RecaptchaWrapper'
import ErrorDisplay from './ErrorDisplay'
import { fetchMagicLink, submitFormData } from '../../services/formAPI'
import { useToken } from '../../utils/context/TokenContext'
import useFormCustomValidation from '../../utils/hooks/useFormCustomValidation'
import useFormValidation from '../../utils/hooks/useFormValidation'
import useFormCache from '../../utils/hooks/useFormCache'
import {
  FormContainer,
  FormSuccess,
  FormBody,
  SubmitButton,
  GetHelp,
} from './styles'
import Spinner from '../Loading/Spinner'
import ProgressBar from '../Loading/ProgressBar'

const FormComponent = ({
  formId,
  customValidation,
  isModal,
  ctaText,
  fromLocation,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [recaptcha, setRecaptcha] = useState('')
  const [magicLinkSent, setMagicLinkSent] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [thanksMessage, setThanksMessage] = useState('')
  const { csrfToken, fetchCsrfToken } = useToken()
  const [token, setToken] = useState(csrfToken)

  const uniqueFormId = isModal ? `mFA${formId}` : `FA${formId}`

  useEffect(() => {
    const fetchToken = async () => {
      if (!csrfToken) {
        try {
          await fetchCsrfToken()
        } catch (error) {
          setErrorMessage('Unable to retrieve token.')
        }
      }
    }

    fetchToken()
  }, [csrfToken, fetchCsrfToken])

  useEffect(() => {
    if (csrfToken) {
      setToken(csrfToken)
    }
  }, [csrfToken])

  const {
    formHTML,
    hiddenFields,
    loading: formLoading,
    error: formError,
  } = useFormCache(formId, token)

  const {
    isFetchingRole,
    fetchUserRole,
    validateAccessRole,
    accessRole,
    error: validationError,
  } = useFormCustomValidation(customValidation)

  const {
    fieldErrors,
    validateField,
    validateAllFields,
    setFieldErrors,
    clearErrors,
    validationRules,
  } = useFormValidation(formHTML, formValues)

  const fieldRefs = useRef({})
  const safeValidationRules = useMemo(
    () => validationRules || {},
    [validationRules]
  )

  const stripHtml = useCallback((html) => {
    if (!html) return ''
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }, [])

  const sendMagicLinkHandler = useCallback(async (email, name, token, from) => {
    try {
      const data = await fetchMagicLink(email, name, token, from)
      setMagicLinkSent(true)
      return data
    } catch (error) {
      if (error.name !== 'AbortError') {
        setErrorMessage('Failed to send magic link. Please try again.')
      }
      throw error
    }
  }, [])

  const handleFormSubmission = useCallback(
    async (email, role, name) => {
      try {
        const formElement = document.getElementById(uniqueFormId)
        if (!formElement) {
          throw new Error('Form not found')
        }

        const responseText = await submitFormData(formElement, recaptcha)
        startTransition(() => {
          setFormValues((prevValues) => ({
            ...prevValues,
            ...DOMPurify.sanitize(responseText, sanitizeConfig),
          }))
        })

        if (formId === 29 && role === 'Institutional') {
          await sendMagicLinkHandler(email, name, token, fromLocation)
        }

        setThanksMessage(stripHtml(responseText))
      } catch (error) {
        if (error.name !== 'AbortError') {
          setErrorMessage(
            error.message ||
              'There was an error submitting the form. Please try again.'
          )
        }
      }
    },
    [
      uniqueFormId,
      recaptcha,
      formId,
      sendMagicLinkHandler,
      stripHtml,
      submitFormData,
    ]
  )

  const submitForm = useCallback(
    async (email, role, name) => {
      try {
        await handleFormSubmission(email, role, name)
      } catch (error) {
        if (error.name !== 'AbortError') {
          setErrorMessage(
            error.message ||
              'There was an error submitting the form. Please try again.'
          )
        }
      } finally {
        setFormSubmitted(true)
        setIsSubmitting(false)
        setErrorMessage('')
      }
    },
    [handleFormSubmission]
  )

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    if (formHTML && hiddenFields && Object.keys(hiddenFields).length > 0) {
      startTransition(() => {
        setFormValues((prevValues) => ({
          ...prevValues,
          ...hiddenFields,
        }))
      })
    }
  }, [formHTML, hiddenFields])

  const handleInputChange = useCallback(
    (e) => {
      const { id, value, type, checked } = e.target
      const fieldValue =
        type === 'checkbox' || type === 'radio' ? checked : value

      setFormValues((prevValues) => ({
        ...prevValues,
        [id]: fieldValue,
      }))
      validateField(id, fieldValue)
    },
    [validateField]
  )

  const handleCustomSubmit = async (event) => {
    event.preventDefault()
    clearErrors()
    setErrorMessage('')

    const { hasError, newFieldErrors } = validateAllFields()

    if (hasError) {
      setFieldErrors(newFieldErrors)
      setErrorMessage('Please fix the errors in the form.')

      const firstErrorField = Object.keys(newFieldErrors)[0]
      if (firstErrorField && fieldRefs.current[firstErrorField]) {
        fieldRefs.current[firstErrorField].focus()
      }
      return
    }

    if (!recaptcha) {
      setErrorMessage('Please complete the CAPTCHA.')
      return
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const emailEntry = Object.entries(formValues).find(
      ([key, value]) =>
        typeof value === 'string' && emailRegex.test(value.trim())
    )

    if (!emailEntry) {
      setErrorMessage('Please provide a valid email address.')
      return
    }

    const [emailKey, emailValue] = emailEntry
    const email = emailValue.trim()

    setIsSubmitting(true)
    setErrorMessage('')

    try {
      if (customValidation) {
        const userData = await fetchUserRole(email, token)
        if (!userData) {
          setIsSubmitting(false)
          return
        }
        const { name, role } = userData
        const { message, isValid } = validateAccessRole(role)
        if (isValid === false) {
          setErrorMessage(message)
          setIsSubmitting(false)
          return
        }
        await submitForm(email, role, name)
      } else {
        await submitForm(email, undefined, undefined)
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        setErrorMessage(
          error.message ||
            'There was an error submitting the form. Please try again.'
        )
      }
      setIsSubmitting(false)
    }
  }

  if (!isClient) {
    return null
  }

  return (
    <FormContainer>
      {formLoading && <Spinner />}
      {!formSubmitted && formHTML && (
        <FormBody
          id={uniqueFormId}
          onSubmit={handleCustomSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
          action="https://forms.harborcapital.com/api_v2/workflow/processor"
          noValidate
        >
          <FormRenderer
            formHTML={formHTML}
            formValues={formValues}
            handleInputChange={handleInputChange}
            fieldErrors={fieldErrors}
            fieldRefs={fieldRefs}
            validationRules={safeValidationRules}
          />

          {!formSubmitted && (
            <>
              <RecaptchaWrapper
                formId={uniqueFormId}
                gatsbyRecaptchaKey={process.env.GATSBY_FA_RECAPTCHA_KEY || ''}
                onRecaptchaChange={setRecaptcha}
                onRecaptchaExpire={() => setRecaptcha('')}
                formSubmitted={formSubmitted}
              />
              {errorMessage && (
                <ErrorDisplay
                  errorMessage={errorMessage}
                  fieldErrors={fieldErrors}
                />
              )}
              {errorMessage && (
                <GetHelp>
                  Looking for additional support?{' '}
                  <a href="mailto:WebTeam@harborcapital.com">Click here</a> to
                  get in touch.
                </GetHelp>
              )}
              <SubmitButton type="submit" disabled={!recaptcha || isSubmitting}>
                {isSubmitting ? 'Submitting...' : ctaText}
              </SubmitButton>
            </>
          )}
        </FormBody>
      )}
      {thanksMessage && <FormSuccess>{thanksMessage}</FormSuccess>}
      {(isSubmitting || formSubmitted) && (
        <ProgressBar isSubmitting={isSubmitting} onComplete={() => {}} />
      )}
    </FormContainer>
  )
}

FormComponent.propTypes = {
  formId: PropTypes.number.isRequired,
  customValidation: PropTypes.string,
  isModal: PropTypes.bool,
  ctaText: PropTypes.string,
  fromLocation: PropTypes.string,
}

export default FormComponent
