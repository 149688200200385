import DOMPurify from 'dompurify'
import { sanitizeConfig, removeFARecaptchaHook } from '../utils/sanitization'

export const fetchUserRoleAPI = async (email, token) => {
  if (!token) {
    throw new Error('Function token is required.')
  }
  const response = await fetch('/api/salesforce-api', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': token,
    },
    credentials: 'same-origin',
    body: JSON.stringify({ email }),
  })

  if (!response.ok) {
    throw new Error(`Error fetching role: ${response.statusText}`)
  }

  return response.json()
}

export const fetchMagicLink = async (email, name, token, fromLocation) => {
  if (!token) {
    throw new Error('Function token is required.')
  }
  if (!email) {
    throw new Error('Email is required.')
  }

  const response = await fetch('/api/magic-link', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': token,
    },
    credentials: 'same-origin',
    body: JSON.stringify({ email, name, fromLocation }),
  })

  if (!response.ok) {
    await response
  }

  return response.json()
}

export const submitFormData = async (formElement, recaptcha) => {
  const formData = new FormData(formElement)
  formData.append('g-recaptcha-response', recaptcha)

  const response = await fetch(formElement.action, {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    await response
  }

  return response.text()
}

export const fetchFormHTML = async (formId, token) => {
  if (!token) {
    throw new Error('Function token is required.')
  }
  DOMPurify.addHook('uponSanitizeElement', removeFARecaptchaHook)

  const response = await fetch(`/api/fetch-fa-form?formId=${formId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': token,
    },
    credentials: 'same-origin',
  })

  if (!response.ok) {
    const errorText = await response.text()
    throw new Error(`Failed to fetch form: ${errorText}`)
  }

  const data = await response.text()
  const sanitizedHTML = DOMPurify.sanitize(data, sanitizeConfig)

  DOMPurify.removeHook('uponSanitizeElement', removeFARecaptchaHook)
  return sanitizedHTML
}
